<template>
  <div class="register">
    <div class="main">
      <p class="fbt">VR-CAMPUS TOUR</p>
      <h3>找回密码</h3>

      <p class="title">手机号码</p>
      <input class="inputTel" type="text" v-model="ruleForm.phone" /><el-button
        class="btnCode"
        :disabled="codeDisabled"
        @click="getCode"
        >获取验证码<span>{{ time }}</span></el-button
      >
      <p class="title">短信验证码</p>
      <input class="input" v-model="ruleForm.smsCode" />
      <p class="title">密码</p>
      <input class="input" type="password" v-model="ruleForm.password" />
      <p class="title">确认密码</p>
      <input class="input" type="password" v-model="ruleForm.repassword" />
      <div>
        <a class="btn" @click="submitForm">确认</a>
      </div>
    </div>
  </div>
</template>

<script>
import { phoneCode, retrievePassword } from "@/api/Login/Login";
export default {
  data() {
    return {
      codeDisabled: false,
      time: "",
      ruleForm: {
        phone: "",
        smsCode: "",
        password: "",
        repassword: "",
      },
    };
  },
  created() {},
  methods: {
    getCode() {
      this.codeDisabled = true;
      phoneCode(this.ruleForm.phone).then((res) => {
        if (!res.status) {
          this.$message(res.msg);
        }
      });
      let time = 60;
      this.time = `(${time}s)`;
      let timer = setInterval(() => {
        time--;
        this.time = `(${time}s)`;
        if (time == 0) {
          this.codeDisabled = false;
          this.time = "";
          clearInterval(timer);
        }
      }, 1000);
    },
    submitForm() {
      if (
        this.ruleForm.phone.trim() == "" ||
        this.ruleForm.phone.length != 11
      ) {
        this.$message("请输入手机号");
      } else if (this.ruleForm.smsCode.trim() == "") {
        this.$message("请输入短信验证码");
      } else if (this.ruleForm.password.trim() == "") {
        this.$message("请输入密码");
      } else if (this.ruleForm.repassword.trim() == "") {
        this.$message("请再次输入密码");
      } else if (
        this.ruleForm.password.trim() != this.ruleForm.repassword.trim()
      ) {
        this.$message("密码不一致");
      } else {
        retrievePassword(this.ruleForm)
          .then((res) => {
            if (res.status) {
              this.$message(res.msg);
              this.$router.push("/");
              return;
            } else {
              this.$message(res.msg);
            }
          })
          .catch(() => {
            this.$toast("获取服务器认证失败");
          }); //捕获异常
      }
    },
  },
};
</script>

<style lang="less">
.register {
  background-color: #0e092b;
  height: 100%;
  .main {
    width: 300px;
    height: 250px;
    border: 1px solid #000;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -125px;
    padding-left: 30px;
    .fbt {
      color: #d14649;
      padding-top: 10px;
    }
    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      padding: 1px 0 20px 0;
    }
    .title {
      padding: 5px 0;
      font-size: 14px;
      color: #fff;
    }
    .input {
      padding: 5px 0;
      font-size: 14px;
      border: 1px solid #333;
      background-color: #0e092b;
      color: #fff;
      width: 280px;
      height: 20px;
    }
    .inputTel {
      padding: 5px 0;
      font-size: 14px;
      border: 1px solid #333;
      background-color: #0e092b;
      color: #fff;
      width: 140px;
      height: 20px;
    }
    .btn1 {
      color: #d14649;
      font-size: 12px;
      display: inline-block;
      line-height: 60px;
    }
    .btn {
      width: 80px;
      height: 20px;
      font-size: 14px;
      background-color: #d14649;
      color: #3e3131;
      text-align: center;
      line-height: 20px;
      display: block;
      margin: 0 auto;
      float: right;
      margin-top: 21px;
      margin-right: 20px;
    }
    .btnCode {
      width: 130px;
      height: 20px;
      font-size: 14px;
      background-color: #646464;
      color: #fff;
      text-align: center;
      line-height: 0;
      display: block;
      margin: 0 auto;
      float: right;
      border: none;
      border-radius: none;

      margin-top: 2px;
      margin-right: 20px;
    }
  }
}
</style>
